.WhyIntro {
    height:350px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:rgb(48, 47, 47);
    text-align: center;
    transform: translateY(30px);
    margin-top:20px;
    
}

.WhyIntro h3 {
    font-weight: 400;
    font-size: 1.6rem;
}

.WhyServices {
    width:100%;
    display: flex;
    justify-content: space-around;
    margin-top:20px;
    padding: 0 5px;
}

.WhyServices .WhyServicesItem {
    width:200px;
    height:100px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}

@media screen and (min-width:1000px) {
    .WhyIntro {
  
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color:rgb(48, 47, 47);
        text-align: center;
        transform: translateY(20px);
        margin-top:20px;
        
    }
    
    .WhyIntro h3 {
        font-weight: 400;
        font-size: 2rem;
    }
    .WhyServices .WhyServicesItem {
        height:160px;
        display: flex;
        flex-direction: column;
       
    }
}
