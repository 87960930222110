.ContactForm {
    background-color:#796a7c;
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0 60px 0;
    color:white;
}
.ContactForm > * {
    margin: 0 5px;
}

.ContactFormInfo {
    text-align: center;
    max-width:500px;
    padding: 30px 5px;
    /* border:1px solid white; */

}
.ContactFormInfo > p {
    font-size: 1.4rem;
    font-weight: 500;

}

.ContactFormForm {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.ContactForm .contactFormHeadline {
    color:white;
    font-weight: 200;
    margin: 10px 0;
    text-align: center;
}
.ContactForm .contactFormGreeting {
    color:white;
    font-weight: 200;
    margin: 10px 0;
    text-align: center;
    position: absolute;
    width:100%;
 
}
.ContactForm .InputFields {
    max-width:600px;

    display: flex;
    
    flex-wrap: wrap;
    justify-content: center;
    
}
.ContactForm .InputFields >:first-child {
    max-width:calc(100% - 6px);

    
}
.ContactForm .InputFields > * {
    max-width:calc(50% - 6px);
    min-width:200px;
    margin:3px;
    flex-grow:1;
    
    
}
.ContactForm .InputFields > div:last-child {
    justify-self: self-start;
}

.contactSubmitButton {
	box-shadow:inset 0px 39px 0px -24px #ffffff;
	background-color:#f9f9f9;
	border-radius:4px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-size:1.2rem;
	padding:6px 15px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;


}

.contactSubmitButton:hover {
    background-color:#e9e9e9;
}
.contactSubmitButton:active {
	
	top:1px;
}

@media screen and (max-width:800px) {
    .ContactForm {
        flex-direction: column;
    }
    .ContactFormInfo {
        padding: 20px 5px;
        margin-bottom: 20px;
    }
    .ContactFormInfo > p {
        font-size: 1.2rem;

    
    }
}
@media screen and (max-width:428px) {
    .ContactForm .InputFields >:first-child {
        max-width:calc(50% - 6px);
        
    }
    .ContactFormInfo > p {
        font-size: 1.1rem;
    
    }
}