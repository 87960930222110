.BurgerMenu a:last-child {
    border-bottom:1px solid rgba(255, 255, 255, 0.425);

}
.LinkItem {
 border-top:1px solid rgb(56, 59, 70);

}
.LinkItem > span {
    padding-left:20px;
    color: #cacaca;
    font-weight: 100;
    font-size: 1.3rem;
}
.BurgerMenuLogotype {
    width:120px;
    transform: translateY(5px);
   
}