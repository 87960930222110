.BurgerMenuFooter {
    display:flex;
    border-top:1px solid rgb(56, 59, 70);
}


.BurgerMenuFooter .Flags {

    display: flex;
    align-items: center;
    margin:20px 0 0 10px;
    justify-self: flex-end;
} 
.BurgerMenuFooter .Flags > * {
    margin:0 5px;
} 
.BurgerMenuFooter .Flags > *:hover {
    cursor: pointer;
} 