
.Services {
   overflow: hidden;
   position: relative;
}

.ServicesBodyOverlay {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-color: #f4f4f496;
    clip-path: polygon(0 0, 59% 0, 28% 100%, 0% 100%);
}

.MainServices {
    position: relative;
}

.Services .ServicesIntro {
    height:300px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:rgb(48, 47, 47);
    text-align: center;
    margin:30px 10px 0 10px;
    
}

.ServicesIntro h3 {
    font-weight: 200;
    font-size: 2.5rem;
    margin-bottom:8px;
}
.ServicesIntro p {
    font-weight: 200;
    font-size: 1.4rem;
}

.ServicesBody {
    display:flex;
    flex-direction: column;
}

.ServicesDescription {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 100px;
}
.ServicesWarehouse {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    
}

.ServicesBody h4 {
    font-size: 3rem;
    font-weight: 400;
    width:500px;
    text-align: center;
    color:white;
    background-color: #615766;
    margin-bottom: 20px;
    padding:5px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.ServicesDescription p {
    font-size: calc(0.5rem + 1.4vw);
    font-weight: 100;
    text-align: center;
    color:rgb(43, 43, 43);
    margin-bottom: 20px;
    max-width:800px;
    
}
.ServicesBody img {
    width:30%;
    max-height:400px;
    padding: 0 10px;
}

@media screen and (max-width:940px) {
    .ServicesIntro h3 {

        font-size: 1.8rem;
    }
    .ServicesIntro p {

        font-size: 1.1rem;
    }
    
    .ServicesDescription {
        justify-content: center;
        align-items: space-around;
        flex-direction: column;
        margin-bottom:20px;
     
    }
    .ServicesBody h4 {
        font-size: 2.2rem;
        width:400px;

    }
    .ServicesDescription img {
        order: 0;
     
    }
    .ServicesDescription p {
        order: 1;
        margin:10px 10px;
     
    }

}
@media screen and (max-width:500px) {
    .ServicesIntro h3 {

        font-size: 1.6rem;
    }


    .ServicesBody h4 {
        font-size: rem;
        width:100%;

    }


}