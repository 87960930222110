.SystemExplanation {
    flex-grow:1;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 2%;
    
}

.SystemExplanation h3 {
    font-weight: 100;
    font-size:1.7rem;
    text-align: center;

}
.SystemExplanation p {
    padding-top:20px;
    font-weight: 100;
    width:100%;
    font-size:1.2rem;
    text-align: center;
    color:#333333;

}

@media (min-width:800px) {
    .SystemExplanation {
        width:50%;
        
    }
    

}

@media (min-width:1280px) {
    .SystemExplanation {
        justify-content: flex-start;
    }
    .SystemExplanation h3 {
        font-size:2.1rem;
    }
    .SystemExplanation p {
        font-size:1.4rem;
    }

}
@media (min-width:1480px) {
    .SystemExplanation {
        justify-content: center;
    }
    .SystemExplanation h3 {
        font-size:3rem;

    }
    .SystemExplanation p {
        font-size:1.6rem;
    }

}