.SystemVisual {
    flex-grow:1;
   /*  min-width: 400px; */
    display: flex;
    justify-content: center;
    padding-left:20px;
    padding-top:20px;
}
.SystemVisualComputer {
    width: 240px;
}
.SystemVisualMobile {
    height: 60px;
    transform: translate(-20px, -20px)
}

@media (min-width:800px) {

    .SystemVisual {
        padding-top:0px;
        min-width: 400px;
    }
}

@media (min-width:1280px) {
    .SystemVisual {
        padding-left:80px;
    }
    .SystemVisualComputer {
        width: 300px;
    }
    .SystemVisualMobile {
        height: 80px;
        transform: translate(-20px, -20px)
    }

}
@media (min-width:1480px) {
    .SystemVisualComputer {
        width: 400px;
    }
    .SystemVisualMobile {
        height: 100px;
        transform: translate(-20px, -20px)
    }

}