.WhyBenefitsItem {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding:20px;
    margin:10px 10px;
/*     border:1px solid gray;
    border-radius: 2px; */
}

.WhyBenefitsItem h3 {
    font-weight: 200;
    font-size: 2rem;
    margin-bottom: 10px;
}
.WhyBenefitsItem p {
    font-weight: 200;
    font-size: 1.5rem;
}
.WhyBenefitsItemBackground {
    position: absolute;
    margin:0 auto;
    display: flex;
    justify-content: center;
    
}
.WhyBenefitsItemBackground {
    position: absolute;
    margin:0 auto;
    display: flex;
    justify-content: center;
    z-index: -1;
    align-self: center;
    transform: translateY(-40px)
    
}
@media screen and (max-width:500px) {
    .WhyBenefitsItem h3 {
        font-weight: 200;
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
    .WhyBenefitsItem p {
        font-weight: 200;
        font-size: 1rem;
    }
    .WhyBenefitsIcon {
        display: none;
    }
}