.ContactFind {
    background:white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 500px;
    margin: 20px 0;
}
.ContactFindDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:20px;
  text-align: center;

}
.ContactFindDescription h2 {
  font-weight: 200;
  font-size: 2rem;
}
.ContactFindDescription > p:nth-child(2) {
  font-weight: 200;
  font-size: 1.3rem;


}
.ContactFindDescription p:nth-child(3) {
  padding-top:20px;
  font-weight: 200;
  font-size: 1.2rem;
  line-height: 1.5rem;
  height:100%;

  

}


@media screen and (max-width:1100px) {
  .ContactFind {
    min-height: 400px;
  } 
}
@media screen and (max-width:840px) {
  .ContactFind {
    flex-direction: column;
    align-items: center;
  } 
}
