.HomeSystem {
    display:flex;
    flex-direction: column;
    width:100%;
    margin-bottom:50px;
    margin-top:150px;
    z-index: 2;

}
.Seperator {
    display: none;


}

@media (min-width:800px) {
    .HomeSystem {
        flex-direction: row;
        margin-bottom:150px;
        width:100%;

    }
    .Seperator {
        display: block;
        flex-grow:0;
        border-left:1px solid rgb(68, 68, 68);
        padding: 0px 1px;
        max-height: 300px;
    
    }

}
@media (min-width:980px) {
    .HomeSystem {
        flex-direction: row;
        margin-top:100px;
      

    }


}