.ContactMap {
    width:600px;
    display:flex;
    justify-content: center;
}
.ContactMapContainer {
    width:500px;
    height:500px;
    position: relative;
}

@media screen and (max-width:1100px) {
    .ContactMapContainer {
        width:400px;
        height:400px;
    } 
    .ContactMap {
        width:400px;
        margin:0 20px;
    }
}
@media screen and (max-width:460px) {
    .ContactMapContainer {
        width:400px;
        height:400px;
    } 
    .ContactMap {
        width:100%;
        margin:0;
    }
}
@media screen and (max-width:420px) {
    .ContactMapContainer {
        width:350px;
        height:350px;
    } 

}
@media screen and (max-width:367px) {
    .ContactMapContainer {
        width:300px;
        height:300px;
    } 

}