.Navigation {
    display:flex;
    align-items:center;
    width:100%;
    color:white;
    position: absolute;
    font-weight: 100;
    background:none;
    font-size:1.3rem;
    z-index: 900;
    height:70px;
   
}
.Navigation a {
    text-decoration: none;
    padding:0px 10px;
    color:white; 
}

.Navigation a:visited {
     color:white; 
}

/* .Navigation a:last-child {
    position: absolute;
    margin-left:50vw;
    transform:translateX(-10vw) translateY(20px);
    width:300px;
} */
.LinkLogotype {
    width:140px;
    padding:5px;
    padding-right:20px;
}

.Navigation .Flags {
    position: absolute;
    right:0;
    display: flex;
    align-items: center;
    margin-left:10px;
    justify-self: flex-end;
} 
.Navigation .Flags > * {
    margin:0 5px;
} 
.Navigation .Flags > *:hover {
    cursor: pointer;
} 


@media (max-width:600px) {
     .TextLink {
        display:none;
    } 
    .Navigation .Flags {
        display:none;
    } 
    
}