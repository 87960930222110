@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
* {
  margin: 0;
  padding: 0;
}
body {

  font-family: 'Roboto', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

    /* overflow-y: overlay; */

}

.Navigation_Navigation__1P-fd {
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    width:100%;
    color:white;
    position: absolute;
    font-weight: 100;
    background:none;
    font-size:1.3rem;
    z-index: 900;
    height:70px;
   
}
.Navigation_Navigation__1P-fd a {
    text-decoration: none;
    padding:0px 10px;
    color:white; 
}

.Navigation_Navigation__1P-fd a:visited {
     color:white; 
}

/* .Navigation a:last-child {
    position: absolute;
    margin-left:50vw;
    transform:translateX(-10vw) translateY(20px);
    width:300px;
} */
.Navigation_LinkLogotype__3MUl2 {
    width:140px;
    padding:5px;
    padding-right:20px;
}

.Navigation_Navigation__1P-fd .Navigation_Flags__2nXzM {
    position: absolute;
    right:0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-left:10px;
    justify-self: flex-end;
} 
.Navigation_Navigation__1P-fd .Navigation_Flags__2nXzM > * {
    margin:0 5px;
} 
.Navigation_Navigation__1P-fd .Navigation_Flags__2nXzM > *:hover {
    cursor: pointer;
} 


@media (max-width:600px) {
     .Navigation_TextLink__2KaCu {
        display:none;
    } 
    .Navigation_Navigation__1P-fd .Navigation_Flags__2nXzM {
        display:none;
    } 
    
}
.Footer_Footer__2Mw-c {
    margin:0;
    padding:0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 500px;
    padding: 20px 20px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    color: #8A6F91;
    background-color: #221C24;
}




.Footer_Footer__2Mw-c address {
    font-style:normal;
    -webkit-order:1;
            order:1;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size:1.5rem;
   

}
.Footer_Footer__2Mw-c address hr {
    border:none;
    height:1px;
    color: #8A6F91;
    background-color: rgba(138, 111, 145, 0.664);
}
.Footer_Footer__2Mw-c .Footer_HomeAddress__2aWf8 {
    padding: 10px 0;
}
.Footer_Footer__2Mw-c .Footer_PagodaPhone__BEWSW {
    text-decoration: none;
    color:#8A6F91;
    margin-left: 5px;
}
.Footer_Footer__2Mw-c .Footer_InfoMail__v6n4k {
    text-decoration: none;
    color:#8A6F91;
}
.Footer_Footer__2Mw-c .Footer_SocialMedia__1OlXP {
    display: -webkit-flex;
    display: flex;
    -webkit-order:2;
            order:2;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}
.Footer_Footer__2Mw-c .Footer_SocialMedia__1OlXP img {
    width:50px;
    margin:0px 10px;
}

.Footer_Footer__2Mw-c section {
    display: -webkit-flex;
    display: flex;
    width:100%;
    display:flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Footer_Footer__2Mw-c .Footer_FooterLogotype__pAWlm {
    height:60px;
}

@media screen and (max-width: 600px) {
    .Footer_Footer__2Mw-c {
        margin:0;
        padding:0;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 400px;
        padding: 20px 20px;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
        color: #8A6F91;
        background-color: #221C24;
    }
    
    .Footer_Footer__2Mw-c section {
        -webkit-flex-direction: column;
                flex-direction: column;
        height:100%;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-around;
                justify-content: space-around;
    }
    .Footer_Footer__2Mw-c address {
        -webkit-order:2;
                order:2;
        text-align: center;
        margin:20px 0;
        font-size:1.2rem;
        
    }
    .Footer_Footer__2Mw-c .Footer_SocialMedia__1OlXP {
        -webkit-order:1;
                order:1
    }

    
}
.BurgerMenu_BurgerMenu__3pq6P a:last-child {
    border-bottom:1px solid rgba(255, 255, 255, 0.425);

}
.BurgerMenu_LinkItem__21M4R {
 border-top:1px solid rgb(56, 59, 70);

}
.BurgerMenu_LinkItem__21M4R > span {
    padding-left:20px;
    color: #cacaca;
    font-weight: 100;
    font-size: 1.3rem;
}
.BurgerMenu_BurgerMenuLogotype__v5X8U {
    width:120px;
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
   
}
.BurgerMenuFooter_BurgerMenuFooter__2gvUN {
    display:-webkit-flex;
    display:flex;
    border-top:1px solid rgb(56, 59, 70);
}


.BurgerMenuFooter_BurgerMenuFooter__2gvUN .BurgerMenuFooter_Flags__3x06S {

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin:20px 0 0 10px;
    justify-self: flex-end;
} 
.BurgerMenuFooter_BurgerMenuFooter__2gvUN .BurgerMenuFooter_Flags__3x06S > * {
    margin:0 5px;
} 
.BurgerMenuFooter_BurgerMenuFooter__2gvUN .BurgerMenuFooter_Flags__3x06S > *:hover {
    cursor: pointer;
} 
.DesktopLayout_BurgerMenu__1aM2y {
    display:none;
}


@media (max-width:600px) {
    .DesktopLayout_BurgerMenu__1aM2y {
        display:block;
    }
    
}

.Home_Home__26EFf {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height:100%; 
}
.Hero_HomeHero__1jeC5 {
    height:110vh;
    /* width:100vw; */
    overflow: hidden;
}
.Hero_HomeHeroOverlay__2uOFi {
    height:110vh;
    width:100%;
    background-color:rgba(22, 22, 22, 0.226);
    z-index: 1;
    position: absolute;
}
.Hero_HomeHeroRoad__1C30N {
    background-image: url(/static/media/logistics-lorry-thin.091ba64b.jpg);
    width:100%;
    height:110vh;
    background-size: cover;
    position: absolute;
    overflow: hidden;
   
    
}
.Hero_HomeHeroSea__2wTPQ {
    background-image: url(/static/media/sea-freight2000.5c58ea2e.jpg);
    width:100%;
    height:110vh;
    background-size: cover;
    position: absolute;
    overflow: hidden;
}
.Hero_HomeHeroAir__16bNB {
    background-image: url(/static/media/air-freight.d62a6510.jpg);
    width:100%;
    height:110vh;
    background-size: cover;
    position: absolute;
    overflow: hidden;
}

.Hero_HomeHero__1jeC5 .Hero_LeftTextBox__2OqLw {
    margin-left:5vw;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: Hero_slideleft__2X5u0;
            animation-name: Hero_slideleft__2X5u0;
}
.Hero_HomeHero__1jeC5 .Hero_RightTextBox__rNGOW {
    margin-right: 5vw;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: Hero_slideright__3_-3N;
            animation-name: Hero_slideright__3_-3N;
}
.Hero_HomeHero__1jeC5 .Hero_LeftTextBox__2OqLw p {
    -webkit-transform:translateX(10vw);
            transform:translateX(10vw);
}
.Hero_HomeHero__1jeC5 .Hero_RightTextBox__rNGOW h2 {
    -webkit-transform:translateX(-10vw);
            transform:translateX(-10vw);

}
.Hero_HomeHero__1jeC5 .Hero_HomeHeroTextBox__IAfIX {
    color: white;
    z-index: 10;
    position: relative;
}
.Hero_HomeHero__1jeC5 .Hero_HomeHeroTextBox__IAfIX h2 {
    font-size: 3rem;
}
.Hero_HomeHero__1jeC5 .Hero_HomeHeroTextBox__IAfIX p {
    font-size: 1rem;
    font-weight: 200;
}
.Hero_HomeHero__1jeC5 .Hero_HomeHeroRoadTextBox__1poqU {
    margin-top:60vh;
}
.Hero_HomeHero__1jeC5 .Hero_HomeHeroSeaTextBox__3-Uoz {
    margin-top:50vh;
    
}
.Hero_HomeHero__1jeC5 .Hero_HomeHeroAirTextBox__21O-y {
    margin-top:200px;
    text-align: right;
}

@media (min-width: 600px) {
    .Hero_HomeHero__1jeC5 .Hero_HomeHeroTextBox__IAfIX h2 {
        font-size: 4rem;
    }
    .Hero_HomeHero__1jeC5 .Hero_HomeHeroTextBox__IAfIX p {
        font-size: 1.5rem;
        font-weight: 200;
    }
    
}
@media (min-width: 740px) {
    .Hero_HomeHeroRoad__1C30N {
        background-image: url(/static/media/logistics-lorry.84f097e8.jpg);

       
        
    }
    
}
@media (min-width: 1300px) {
    .Hero_HomeHero__1jeC5 .Hero_HomeHeroTextBox__IAfIX h2 {
        font-size: 6rem;
    }
    .Hero_HomeHero__1jeC5 .Hero_HomeHeroTextBox__IAfIX p {
        font-size: 2rem;
        font-weight: 200;
    }
    
}


@-webkit-keyframes Hero_slideleft__2X5u0 {
  from {
    -webkit-transform:translateX(-100vw);
            transform:translateX(-100vw)
  }

  to {
    -webkit-transform:translateX(0vw);
            transform:translateX(0vw)
   
  }
}


@keyframes Hero_slideleft__2X5u0 {
  from {
    -webkit-transform:translateX(-100vw);
            transform:translateX(-100vw)
  }

  to {
    -webkit-transform:translateX(0vw);
            transform:translateX(0vw)
   
  }
}
@-webkit-keyframes Hero_slideright__3_-3N {
    from {
        -webkit-transform:translateX(100vw);
                transform:translateX(100vw)
      }
    
      to {
        -webkit-transform:translateX(0vw);
                transform:translateX(0vw)
       
      }
}
@keyframes Hero_slideright__3_-3N {
    from {
        -webkit-transform:translateX(100vw);
                transform:translateX(100vw)
      }
    
      to {
        -webkit-transform:translateX(0vw);
                transform:translateX(0vw)
       
      }
}
.Intro_HomeIntro__ae0SC {
    height:900px;
    width:100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    color:#333333;
    margin-top:-10vh;
}

.Intro_HomeIntro__ae0SC .Intro_Title__PNs9J {

    left:20%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background-color: #453748;
    position: absolute;
    z-index: 2;
    margin-top:10px;
    padding: 10px 20px;
    margin-left:-15px;
}
.Intro_HomeIntro__ae0SC .Intro_TitleIE__2NCWo {

    width: -webkit-max-content;

    width: -moz-max-content;

    width: max-content;
    position: absolute;
    z-index: 2;
    margin-top:10px;
    padding: 10px 20px;
    margin-left:-15px;
    width: 100%;

}
.Intro_HomeIntro__ae0SC .Intro_TitleIE__2NCWo h2 {

    font-weight:200;
    font-size: calc(1.2rem + 2.5vw);
    text-align: center;
    color:rgb(63, 63, 63);

}

.Intro_HomeIntro__ae0SC .Intro_Title__PNs9J h2 {
  
    font-weight:200;
    font-size: calc(1.2rem + 2.5vw);
    text-align: center;
    color:white;
}
.Intro_ArticleContainer__1TPsb {
    -webkit-clip-path: polygon(0 7%, 100% 0, 100% 42%, 0% 100%);
            clip-path: polygon(0 7%, 100% 0, 100% 42%, 0% 100%);
    height:100vh;
    width:100%;
    background:#E2DBE3;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    z-index: 1;
    position: absolute;
 
}
.Intro_ArticleContainerIE__MDJBX {
    height:100vh;
    max-height:500px;
    width:100%;
    background:#E2DBE3;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    z-index: 1;
    position: absolute;
 
}
.Intro_ArticleContainer__1TPsb article, .Intro_ArticleContainerIE__MDJBX article {
    margin-top: 110px;
    padding: 20px;
    width:90%;
    font-size:1.2rem;
    text-align: center;
    font-weight:100;

}
.Intro_IntroServicesContainer__1klcn {
   /*  transform:translateY(-20vh); */
    z-index: 1;
    width:100%;
    position: absolute;

}

@media (min-width: 400px) {

    .Intro_ArticleContainer__1TPsb article {
 
        margin-top: 170px;

    }
    .Intro_HomeIntro__ae0SC .Intro_Title__PNs9J {

        margin-left:0px;
    }
    
}
@media (min-width: 600px) {
    .Intro_HomeIntro__ae0SC .Intro_Title__PNs9J {
        left:10%;
        -webkit-transform: rotateZ(-4deg);
                transform: rotateZ(-4deg);
        margin-top:12px;
    }
    .Intro_ArticleContainer__1TPsb article, .Intro_ArticleContainerIE__MDJBX article {
        width:90%;
        font-size:1.4rem;
        margin-top: 170px;

    }
    
}
@media (min-width: 1300px) {
    .Intro_HomeIntro__ae0SC .Intro_Title__PNs9J {
        -webkit-transform: rotateZ(-2deg);
                transform: rotateZ(-2deg)  
    }
    .Intro_ArticleContainer__1TPsb article, .Intro_ArticleContainerIE__MDJBX article {
        width:70%;
        font-size:1.6rem;

    }
    
}
.IntroServices_IntroServices__26ReV {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width:100%;
    margin-top:calc(1.2rem + 450px);
}

.IntroServices_IntroServices__26ReV .IntroServices_IntroServiceBox__3OiOS {
    min-width: 400px;
    min-height: 250px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.2s ease-in-out;
    text-decoration: none;

}
.IntroServices_IntroServices__26ReV .IntroServices_IntroServiceBox__3OiOS p {
    color:white;
    font-weight: 500;
    /* font-size: 3vmax; inline styles to support IE*/
}

.IntroServices_IntroServices__26ReV .IntroServices_IntroServiceBox__3OiOS:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
    opacity: 0.7;

}

.IntroServices_IntroServiceAir__1Wixy {
    background:url(/static/media/air-freight.d62a6510.jpg);
    background-size: cover;
    
}
.IntroServices_IntroServiceRoad__3pEHr {
    background:url(/static/media/logistics-lorry.84f097e8.jpg);
    background-size: cover;
}
.IntroServices_IntroServiceSea__22zLO {
    background:url(/static/media/sea-freight2000.5c58ea2e.jpg);
    background-size: cover;
}

.IntroServices_IntroServiceExplanation__1FVO8 {
    font-weight:400;
    width: 100%;
    text-align: center;
    font-size: 2.2rem;
    margin-top:-80px;
    position: absolute;
}

@media (max-width:1280px) {

    .IntroServices_IntroServices__26ReV .IntroServices_IntroServiceBox__3OiOS {

        min-width: 300px;
        min-height: 200px;
        margin:20px 0;

    }
    .IntroServices_IntroServiceExplanation__1FVO8 {

        font-size: 2rem;
        margin-top:-60px;
    }
}
@media (max-width:980px) {
    .IntroServices_IntroServices__26ReV {
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        margin-top:calc(1.2rem + 400px);

    }
    .IntroServices_IntroServiceAir__1Wixy {
        background:url(/static/media/air-freight-horizontal.d62a6510.jpg);
        background-size: cover;
        
    }
    .IntroServices_IntroServiceRoad__3pEHr {
        background:url(/static/media/logistics-lorry-horizontal.037c689b.jpg);
        background-size: cover;
    }
    .IntroServices_IntroServiceSea__22zLO {
        background:url(/static/media/sea-freight2000-horizontal.ef9261e0.jpg);
        background-size: cover;
    }
    .IntroServices_IntroServices__26ReV .IntroServices_IntroServiceBox__3OiOS {
        min-width: 60%;
        min-height: 100px;
        margin:20px 0;

    }
    .IntroServices_IntroServiceExplanation__1FVO8 {
        font-size: 1.5rem;
        margin-top:0px;
        position: static;
    }
}
@media (max-width:600px) {
    .IntroServices_IntroServices__26ReV {
        margin-top:calc(1.2rem + 450px);
    }
    .IntroServices_IntroServiceExplanation__1FVO8 {
        font-size: 1.2rem;
    }
    .IntroServices_IntroServiceExplanation__1FVO8 {
        font-weight:400;
        width: 100%;
        text-align: center;
        font-size: 1.2rem;

    }
}
.System_HomeSystem__3k0A8 {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width:100%;
    margin-bottom:50px;
    margin-top:150px;
    z-index: 2;

}
.System_Seperator__3ZKxt {
    display: none;


}

@media (min-width:800px) {
    .System_HomeSystem__3k0A8 {
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-bottom:150px;
        width:100%;

    }
    .System_Seperator__3ZKxt {
        display: block;
        -webkit-flex-grow:0;
                flex-grow:0;
        border-left:1px solid rgb(68, 68, 68);
        padding: 0px 1px;
        max-height: 300px;
    
    }

}
@media (min-width:980px) {
    .System_HomeSystem__3k0A8 {
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-top:100px;
      

    }


}
.SystemExplanation_SystemExplanation__3w7Ef {
    -webkit-flex-grow:1;
            flex-grow:1;
    max-width: 800px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 0px 2%;
    
}

.SystemExplanation_SystemExplanation__3w7Ef h3 {
    font-weight: 100;
    font-size:1.7rem;
    text-align: center;

}
.SystemExplanation_SystemExplanation__3w7Ef p {
    padding-top:20px;
    font-weight: 100;
    width:100%;
    font-size:1.2rem;
    text-align: center;
    color:#333333;

}

@media (min-width:800px) {
    .SystemExplanation_SystemExplanation__3w7Ef {
        width:50%;
        
    }
    

}

@media (min-width:1280px) {
    .SystemExplanation_SystemExplanation__3w7Ef {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }
    .SystemExplanation_SystemExplanation__3w7Ef h3 {
        font-size:2.1rem;
    }
    .SystemExplanation_SystemExplanation__3w7Ef p {
        font-size:1.4rem;
    }

}
@media (min-width:1480px) {
    .SystemExplanation_SystemExplanation__3w7Ef {
        -webkit-justify-content: center;
                justify-content: center;
    }
    .SystemExplanation_SystemExplanation__3w7Ef h3 {
        font-size:3rem;

    }
    .SystemExplanation_SystemExplanation__3w7Ef p {
        font-size:1.6rem;
    }

}
.SystemVisual_SystemVisual__1bGqa {
    -webkit-flex-grow:1;
            flex-grow:1;
   /*  min-width: 400px; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding-left:20px;
    padding-top:20px;
}
.SystemVisual_SystemVisualComputer__1V-e- {
    width: 240px;
}
.SystemVisual_SystemVisualMobile__2hhtv {
    height: 60px;
    -webkit-transform: translate(-20px, -20px);
            transform: translate(-20px, -20px)
}

@media (min-width:800px) {

    .SystemVisual_SystemVisual__1bGqa {
        padding-top:0px;
        min-width: 400px;
    }
}

@media (min-width:1280px) {
    .SystemVisual_SystemVisual__1bGqa {
        padding-left:80px;
    }
    .SystemVisual_SystemVisualComputer__1V-e- {
        width: 300px;
    }
    .SystemVisual_SystemVisualMobile__2hhtv {
        height: 80px;
        -webkit-transform: translate(-20px, -20px);
                transform: translate(-20px, -20px)
    }

}
@media (min-width:1480px) {
    .SystemVisual_SystemVisualComputer__1V-e- {
        width: 400px;
    }
    .SystemVisual_SystemVisualMobile__2hhtv {
        height: 100px;
        -webkit-transform: translate(-20px, -20px);
                transform: translate(-20px, -20px)
    }

}

.Services_Services__1Cm4J {
   overflow: hidden;
   position: relative;
}

.Services_ServicesBodyOverlay__2LrdH {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-color: #f4f4f496;
    -webkit-clip-path: polygon(0 0, 59% 0, 28% 100%, 0% 100%);
            clip-path: polygon(0 0, 59% 0, 28% 100%, 0% 100%);
}

.Services_MainServices__xon-6 {
    position: relative;
}

.Services_Services__1Cm4J .Services_ServicesIntro__2j6Nl {
    height:300px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color:rgb(48, 47, 47);
    text-align: center;
    margin:30px 10px 0 10px;
    
}

.Services_ServicesIntro__2j6Nl h3 {
    font-weight: 200;
    font-size: 2.5rem;
    margin-bottom:8px;
}
.Services_ServicesIntro__2j6Nl p {
    font-weight: 200;
    font-size: 1.4rem;
}

.Services_ServicesBody__3pkw2 {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Services_ServicesDescription__2LeEr {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 100px;
}
.Services_ServicesWarehouse__2m-9H {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px;
    
}

.Services_ServicesBody__3pkw2 h4 {
    font-size: 3rem;
    font-weight: 400;
    width:500px;
    text-align: center;
    color:white;
    background-color: #615766;
    margin-bottom: 20px;
    padding:5px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Services_ServicesDescription__2LeEr p {
    font-size: calc(0.5rem + 1.4vw);
    font-weight: 100;
    text-align: center;
    color:rgb(43, 43, 43);
    margin-bottom: 20px;
    max-width:800px;
    
}
.Services_ServicesBody__3pkw2 img {
    width:30%;
    max-height:400px;
    padding: 0 10px;
}

@media screen and (max-width:940px) {
    .Services_ServicesIntro__2j6Nl h3 {

        font-size: 1.8rem;
    }
    .Services_ServicesIntro__2j6Nl p {

        font-size: 1.1rem;
    }
    
    .Services_ServicesDescription__2LeEr {
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: space-around;
                align-items: space-around;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-bottom:20px;
     
    }
    .Services_ServicesBody__3pkw2 h4 {
        font-size: 2.2rem;
        width:400px;

    }
    .Services_ServicesDescription__2LeEr img {
        -webkit-order: 0;
                order: 0;
     
    }
    .Services_ServicesDescription__2LeEr p {
        -webkit-order: 1;
                order: 1;
        margin:10px 10px;
     
    }

}
@media screen and (max-width:500px) {
    .Services_ServicesIntro__2j6Nl h3 {

        font-size: 1.6rem;
    }


    .Services_ServicesBody__3pkw2 h4 {
        font-size: rem;
        width:100%;

    }


}
.ServiceAir_ServiceAir__3lMJa {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width:100%;
    border-top:3px solid #615766;
}

.ServiceRoad_ServiceRoad__OS0Pw {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width:100%;
    border-top:3px solid #615766;
}

.ServiceSea_ServiceSea__1jaqS {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width:100%;
    border-top:3px solid #615766;
}

.ServiceWarehouse_ServiceWarehouse__3X7Wa {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 50px 0; 
}

.ServiceWarehouse_ServiceWarehouse__3X7Wa h4 {
    font-size: 2.8rem;
    text-align: center;
    margin: 20px 0 10px 0;
    color:#424141;
    
}
.ServiceWarehouse_ServiceWarehouse__3X7Wa p {
    font-size: calc(0.5rem + 1.4vw);
    font-weight: 200;
    text-align: center;
    max-width: 80%;
    margin: 10px 0;
    color:rgb(46, 46, 46);
    
}
.ServiceWarehouse_ServiceWarehouse__3X7Wa img {
    width: 500px;

    margin: 40px 0;
    /* border: 1px solid rgb(99, 99, 99); */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;
    
}

@media screen and (max-width:840px) {
    .ServiceWarehouse_ServiceWarehouse__3X7Wa h4 {
        font-size: 2.1rem;
    }

    .ServiceWarehouse_ServiceWarehouse__3X7Wa img {
        /* width:500px; */
        width: 400px;

    }
}
@media screen and (max-width:617px) {
    .ServiceWarehouse_ServiceWarehouse__3X7Wa h4 {
        font-size: 1.8rem;

        
    }
    .ServiceWarehouse_ServiceWarehouse__3X7Wa img {
        /* width:500px; */
        width: 350px;

        
    }
}
@media screen and (max-width:428px) {
    .ServiceWarehouse_ServiceWarehouse__3X7Wa h4 {
        font-size: 1.6rem;

        
    }
    .ServiceWarehouse_ServiceWarehouse__3X7Wa {

        padding: 20px 0; 
    }
    .ServiceWarehouse_ServiceWarehouse__3X7Wa img {
        /* width:500px; */
        width: 250px;

        
    }
}
.ServiceConsulting_ServiceConsulting__H1RW1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 80px 0;
    position: relative;
    height:678px
}

.ServiceConsulting_ConsultingContent__wRJKp {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    -webkit-align-items:flex-start;
            align-items:flex-start;
    
}

.ServiceConsulting_ServiceConsulting__H1RW1 h4 {
    font-size: 4rem;
    font-weight:400;
    text-align: left;
    color:#615766;
    width:80%;
    padding-left:10px;
    -webkit-transform:translateY(-50px);
            transform:translateY(-50px)

}
.ServiceConsulting_ConsultingImageContainer__uLqnn {
    position:absolute;
    right:50px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding:20px;
}
.ServiceConsulting_ParagraphContainer__29nl4 {
    padding:20px 50% 20px 40px;
    background:#615766;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


}
.ServiceConsulting_ServiceConsulting__H1RW1 p {
    font-size: calc(0.5rem + 1.1vw);
    font-weight: 200;
    text-align: center;
    margin: 10px 0;
    color:white;
    
}
.ServiceConsulting_ServiceConsulting__H1RW1 img {
    width: 500px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
}
@media screen and (max-width:1200px) {
    .ServiceConsulting_ServiceConsulting__H1RW1 img {
        width: 400px;
    }
    .ServiceConsulting_ConsultingImageContainer__uLqnn {
        right:5px;
    }
}

@media screen and (max-width:880px) {
    .ServiceConsulting_ServiceConsulting__H1RW1 h4 {
        font-size: 3rem;

    }
    .ServiceConsulting_ConsultingContent__wRJKp {
        -webkit-flex-direction:column;
                flex-direction:column;
    }
    .ServiceConsulting_ConsultingContent__wRJKp > div{
        -webkit-order:2;
                order:2;
    }
    .ServiceConsulting_ConsultingContent__wRJKp > p {
        -webkit-order:1;
                order:1;
    }
    .ServiceConsulting_ConsultingImageContainer__uLqnn {
        right: 0;
        left:50%;
        width:-webkit-fit-content;
        width:-moz-fit-content;
        width:fit-content;
        bottom:90px;
        padding:0;
        -webkit-transform:translateX(-50%);
                transform:translateX(-50%)
    }
    .ServiceConsulting_ServiceConsulting__H1RW1 img {
        width: 300px;
    }
    .ServiceConsulting_ParagraphContainer__29nl4 {
        padding:5px 0 50px 0;
    }
    .ServiceConsulting_ServiceConsulting__H1RW1 {
        height:600px
    }
}
@media screen and (max-width:617px) {
    .ServiceConsulting_ServiceConsulting__H1RW1 {
        padding: 70px 0;
    }
    .ServiceConsulting_ServiceConsulting__H1RW1 h4 {
        font-size: 2.7rem;
    }
}
@media screen and (max-width:428px) {
    .ServiceConsulting_ServiceConsulting__H1RW1 h4 {
        font-size: 1.6rem;
        -webkit-transform:translateY(-10px);
                transform:translateY(-10px)
    }
    .ServiceConsulting_ServiceConsulting__H1RW1 {
        padding: 20px 0;
        height:470px
    }
    .ServiceConsulting_ServiceConsulting__H1RW1 img {

        width: 240px;
        -webkit-transform:translateY(10px);
                transform:translateY(10px)
        
    }
}
@media screen and (max-width:380px) {

    .ServiceConsulting_ServiceConsulting__H1RW1 img {

        -webkit-transform:translateY(30px);

                transform:translateY(30px)
        
    }
}
.ServicesHeader_ServicesHeaderHero__1nmbY {
    /* background-color:#9A909C; */
    width:100%;
    height:280px;
    
}

.ServicesHeader_ServicesHeaderHero__1nmbY .ServicesHeader_ServicesHeaderOverlay__1zkRT {
    position: absolute;
    width:100%;
    height:100%;
    background:rgba(53, 53, 53, 0.707);
    z-index: 2;
}

.ServicesHeader_ServicesHeaderHero__1nmbY .ServicesHeader_ServicesHeaderText__1ncOl {
    font-size: 3.5rem;
    font-weight: 400;
    color:white;
    position: absolute;
    margin-top:-170px; 
    left:10vw;
    z-index: 3;

}
.ServicesHeader_ServicesHeaderHero__1nmbY .ServicesHeader_TextBox__3AL40 {
    position: absolute;
    background-color: #a194a4;
    height:100px;
    padding:20px 0;
    color:rgb(231, 231, 231);
    font-size:1.4rem;
    font-weight:200;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top:-50px;
    right:17vw;
    z-index: 3;
    width:600px;

}
.ServicesHeader_ServicesHeaderHero__1nmbY .ServicesHeader_TextBox__3AL40 p {
    width:90%;
}

.ServicesHeader_ServicesHeaderCover__30K-Q { 
    position: relative;
    width: 100%;
    height: 100%;
  }
.ServicesHeader_ServicesHeaderCover__30K-Q img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }

  @media screen and (max-width:1100px) {
    .ServicesHeader_ServicesHeaderHero__1nmbY .ServicesHeader_TextBox__3AL40 {
        height:100px;
        padding:20px 0;
        width:450px;
        font-size:1.2rem;
        
    }
  }
  @media screen and (max-width:900px) {
    .ServicesHeader_ServicesHeaderHero__1nmbY .ServicesHeader_TextBox__3AL40 {
        left:0 !important;
        right:0 !important;
        margin-left: auto;
        margin-right: auto;
    }
    .ServicesHeader_ServicesHeaderHero__1nmbY .ServicesHeader_ServicesHeaderText__1ncOl {
        font-size: 3rem;
        left:0 !important;
        right:0 !important;
        text-align: center;
    
    }
  }
  @media screen and (max-width:500px) {
    .ServicesHeader_ServicesHeaderHero__1nmbY .ServicesHeader_TextBox__3AL40 {
        font-size: 1rem;
        height:80px;
        padding:10px 0;
        width: calc(96vw - 10px);
        margin-top:-6vh; 
    }
    .ServicesHeader_ServicesHeaderHero__1nmbY .ServicesHeader_ServicesHeaderText__1ncOl {
        font-size: 2.5rem;

    
    }
  }
.About_About__3-P-H {
    height:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.About_AboutBody__3cUNn {
    height:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: #E2DBE3;
}
.AboutHeader_AboutHeader__1KyUm {
  height: 70vh;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 0 5px 1px #909090;
}

.AboutHeader_AboutHeader__1KyUm img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1377px) {
  .AboutHeader_AboutHeader__1KyUm {
    height: 470px;
    overflow: hidden;
  }
}
@media screen and (max-width: 1070px) {
  .AboutHeader_AboutHeader__1KyUm {
    height: 340px;
    overflow: hidden;
  }
}
@media screen and (max-width: 890px) {
  .AboutHeader_AboutHeader__1KyUm {
    height: 280px;
  }
}
@media screen and (max-width: 590px) {
  .AboutHeader_AboutHeader__1KyUm {
    height: 200px;
  }
}
@media screen and (max-width: 420px) {
  .AboutHeader_AboutHeader__1KyUm {
    height: 160px;
  }
}

.AboutProfile_AboutProfile__3gPiL {
    display:-webkit-flex;
    display:flex;
    margin:2vw;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top:80px;

}
.AboutProfile_AboutProfile__3gPiL .AboutProfile_ProfileInfo__1GwHc {
    -webkit-align-self: flex-start;
            align-self: flex-start;

}

.AboutProfile_AboutProfileMobileName__21mER {
    display:none;

}
.AboutProfile_AboutProfile__3gPiL h2 {    
    margin-left:20px;
    font-size:1.8rem;
    font-weight: 400;
   

}
.AboutProfile_AboutProfile__3gPiL  a {    
    margin-left:20px;
    color:purple;

   

}
.AboutProfile_AboutProfile__3gPiL .AboutProfile_ProfileInfo__1GwHc p {    
    margin-top:40px;

}

.AboutProfile_AboutProfile__3gPiL img {
    width:300px;
    box-shadow: 1px 1px 3px 1px #b6b6b6;
    border:1px solid rgb(83, 82, 82);
}
.AboutProfile_AboutProfile__3gPiL .AboutProfile_ProfileInfo__1GwHc p {
    max-width:600px;
    padding:0px 20px;
    
}
@media screen and (max-width:1377px) {
    .AboutProfile_AboutProfile__3gPiL img {
        width:200px;
    }

}
@media screen and (max-width:1070px) {
    .AboutProfile_AboutProfile__3gPiL img {
        width:180px;
    }

}
@media screen and (max-width:890px) {
    .AboutProfile_AboutProfile__3gPiL img {
        width:170px;
    }
    .AboutProfile_AboutProfile__3gPiL .AboutProfile_ProfileInfo__1GwHc p {    
        margin-top:20px;
    
    }

}
@media screen and (max-width:590px) {
    .AboutProfile_AboutProfile__3gPiL {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-top:50px;
    
    }
    .AboutProfile_AboutProfile__3gPiL > * {
        margin:10px 0;
    
    }
    .AboutProfile_AboutProfile__3gPiL .AboutProfile_ProfileInfo__1GwHc {
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-order:2;
                order:2;
    }
    .AboutProfile_AboutProfile__3gPiL .AboutProfile_ProfileInfo__1GwHc p {
        text-align: center;
        margin-top:0px;
    }
    .AboutProfile_AboutProfile__3gPiL  h2 {   
        text-align: center; 
        margin-left:0px;
        font-size:1.8rem;
        font-weight: 400;
       
    
    }
    .AboutProfile_AboutProfile__3gPiL  a {    
        margin-left:0px;
        -webkit-align-self:center;
                align-self:center;
    }
    .AboutProfile_AboutProfile__3gPiL .AboutProfile_ProfileInfo__1GwHc  h2 {   
        display:none;
       
    
    }
    .AboutProfile_AboutProfile__3gPiL .AboutProfile_ProfileInfo__1GwHc  a {    
        display:none;
    }
    .AboutProfile_AboutProfileMobileName__21mER {
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
    }

}

.ProfileSkill_ProfileSkills__1-HHb {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-order:0;
            order:0;
    width:200px;
    margin-top:-170px;
    
}
.ProfileSkill_ProfileSkillItem__oLdls {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 10px;
}
.ProfileSkill_CrossWrapper__1NzS7 {
      width:25px;
}
.ProfileSkill_ProfileSkillCross__3hqG9 {
    background: #4b3c4f;
    height: 20px;
    position: relative;
    width: 4px;
  }
.ProfileSkill_ProfileSkillCross__3hqG9:after {
    background: #4b3c4f;
    content: "";
    height: 4px;
    left: -8px;
    position: absolute;
    top: 8px;
    width: 20px;
  }

  @media screen and (max-width:1377px) {
    .ProfileSkill_ProfileSkills__1-HHb {

        margin-top:0px;
        
    }

}

  @media screen and (max-width:590px) {
    .ProfileSkill_ProfileSkills__1-HHb {
        margin-left:25%;
        
    }

}
.Why_Why__1ufev {
    height:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.WhyHeader_WhyHeaderHero__2i5hZ {
    background-color:#9A909C;
    width:100%;
    height:280px;
    
}

.WhyHeader_WhyHeaderHero__2i5hZ .WhyHeader_WhyHeaderOverlay__2z3IS {
    position: absolute;
    width:100%;
    height:100%;
    background:rgba(53, 53, 53, 0.707);
    z-index: 2;
}

.WhyHeader_WhyHeaderHero__2i5hZ .WhyHeader_WhyHeaderText__yaG74 {
    font-size: 3.5rem;
    font-weight: 400;
    color:white;
    position: absolute;
    margin-top:-170px; 
    right:10vw;
    z-index: 3;

}
.WhyHeader_WhyHeaderHero__2i5hZ .WhyHeader_TextBox__3vPbv {
    position: absolute;
    background-color: #e5e5e5;
    height:100px;
    padding:20px 0;
    color:rgb(29, 28, 28);
    font-size:1.4rem;
    font-weight:200;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top:-50px;
    left:17vw;
    z-index: 3;
    width:600px;

}
.WhyHeader_WhyHeaderHero__2i5hZ .WhyHeader_TextBox__3vPbv p {
    width:90%;
}

.WhyHeader_WhyHeaderCover__2m9mc { 
    position: relative;
    width: 100%;
    height: 100%;
  }
.WhyHeader_WhyHeaderCover__2m9mc img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }

  @media screen and (max-width:1100px) {
    .WhyHeader_WhyHeaderHero__2i5hZ .WhyHeader_TextBox__3vPbv {
        height:100px;
        padding:20px 0;
        width:450px;
        font-size:1.2rem;
        
    }
  }
  @media screen and (max-width:900px) {
    .WhyHeader_WhyHeaderHero__2i5hZ .WhyHeader_TextBox__3vPbv {
        left:0 !important;
        right:0 !important;
        margin-left: auto;
        margin-right: auto;
    }
    .WhyHeader_WhyHeaderHero__2i5hZ .WhyHeader_WhyHeaderText__yaG74 {
        font-size: 3rem;
        left:0 !important;
        right:0 !important;
        text-align: center;
    
    }
  }
  @media screen and (max-width:500px) {
    .WhyHeader_WhyHeaderHero__2i5hZ .WhyHeader_TextBox__3vPbv {
        font-size: 1rem;
        height:80px;
        padding:10px 0;
        width: calc(96vw - 10px);
        margin-top:-6vh; 
    }
    .WhyHeader_WhyHeaderHero__2i5hZ .WhyHeader_WhyHeaderText__yaG74 {
        font-size: 2.5rem;

    
    }
  }
.WhyIntro_WhyIntro__M_1LG {
    height:350px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color:rgb(48, 47, 47);
    text-align: center;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    margin-top:20px;
    
}

.WhyIntro_WhyIntro__M_1LG h3 {
    font-weight: 400;
    font-size: 1.6rem;
}

.WhyIntro_WhyServices__y0j28 {
    width:100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-top:20px;
    padding: 0 5px;
}

.WhyIntro_WhyServices__y0j28 .WhyIntro_WhyServicesItem__3kRf3 {
    width:200px;
    height:100px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
   
}

@media screen and (min-width:1000px) {
    .WhyIntro_WhyIntro__M_1LG {
  
        display:-webkit-flex;
  
        display:flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        color:rgb(48, 47, 47);
        text-align: center;
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
        margin-top:20px;
        
    }
    
    .WhyIntro_WhyIntro__M_1LG h3 {
        font-weight: 400;
        font-size: 2rem;
    }
    .WhyIntro_WhyServices__y0j28 .WhyIntro_WhyServicesItem__3kRf3 {
        height:160px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
       
    }
}

.WhyBenefits_WhyBenefits__2VXeh {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width:100%;
    border-top:3px solid #383838;
}
.WhyBenefits_WhyBenefitsHeader__2BsPC h4 {
    font-size: 2.2rem;
    font-weight: 100;
    width:500px;
    text-align: center;
    color:white;
    background-color: #383838;
    margin-bottom: 20px;
    padding:12px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.WhyBenefits_WhyBenefitsItemWrapper__19Fuc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap:wrap;
            flex-wrap:wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

@media screen and (max-width:1000px) {
    .WhyBenefits_WhyBenefitsHeader__2BsPC h4 {
        font-size: 1.9rem;
        font-weight: 100;
        width:400px;
      
    }
}
@media screen and (max-width:500px) {
    .WhyBenefits_WhyBenefitsHeader__2BsPC h4 {
        font-size: 1.9rem;
        font-weight: 100;
        width:100%;
      
    }
}

.WhyBenefitsItem_WhyBenefitsItem__3abpF {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 400px;
    padding:20px;
    margin:10px 10px;
/*     border:1px solid gray;
    border-radius: 2px; */
}

.WhyBenefitsItem_WhyBenefitsItem__3abpF h3 {
    font-weight: 200;
    font-size: 2rem;
    margin-bottom: 10px;
}
.WhyBenefitsItem_WhyBenefitsItem__3abpF p {
    font-weight: 200;
    font-size: 1.5rem;
}
.WhyBenefitsItem_WhyBenefitsItemBackground__26Jnv {
    position: absolute;
    margin:0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    
}
.WhyBenefitsItem_WhyBenefitsItemBackground__26Jnv {
    position: absolute;
    margin:0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: -1;
    -webkit-align-self: center;
            align-self: center;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px)
    
}
@media screen and (max-width:500px) {
    .WhyBenefitsItem_WhyBenefitsItem__3abpF h3 {
        font-weight: 200;
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
    .WhyBenefitsItem_WhyBenefitsItem__3abpF p {
        font-weight: 200;
        font-size: 1rem;
    }
    .WhyBenefitsItem_WhyBenefitsIcon__3iQRs {
        display: none;
    }
}
.Contact_Contact__2esCz {
    
    height:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  
}



.ContactHeader_ContactHeaderHero__1K1Qu {

    /* background-color:rgba(92, 115, 120, 0.76); */
    background-color:rgba(154, 147, 158, 0.8);
    
    
    width:100%;
    height:350px;
    overflow: hidden;
    
}

.ContactHeader_ContactHeaderHero__1K1Qu .ContactHeader_ContactHeaderOverlay__1Ad_J {
    position: absolute;
    width:100%;
    height:100%;
    background:rgba(32, 22, 41, 0.76);
    z-index: 2;
}

.ContactHeader_ContactHeaderHero__1K1Qu .ContactHeader_TextBox__Mtm7D {
    position: absolute;
    font-size:1.6rem;
    font-weight:200;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top:50px;
    margin-left:180px; 
    z-index: 3;
    width:600px;
    color:#3a3939;
    /* color:rgb(78, 78, 78); */
    top:70px;
}
.ContactHeader_ContactHeaderHero__1K1Qu .ContactHeader_TextBox__Mtm7D h2 {
    -webkit-align-self: flex-start;
            align-self: flex-start;
}


.ContactHeader_ContactHeaderCover__3-Nei { 
    position: relative;
    width: 100%;
    height: 100%;
  }

.ContactHeader_ContactBackgroundIllustration__2GmFj {
    position: absolute;
    width:100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    
}
.ContactHeader_ContactBackgroundIllustration__2GmFj img {
    width:50vw;
    margin-right: 10vw;   
}

@media screen and (max-width:1500px) {
    .ContactHeader_ContactHeaderHero__1K1Qu .ContactHeader_TextBox__Mtm7D {
        font-size:1.3rem;
        margin-left:70px; 
        width:400px;

    }
}
@media screen and (max-width:963px) {
    .ContactHeader_ContactHeaderHero__1K1Qu .ContactHeader_TextBox__Mtm7D {
        font-size:1.5rem;
        margin-left:auto; 
        margin-right:auto;
        left:0;
        right:0;
        width:500px;

    }
    .ContactHeader_ContactBackgroundIllustration__2GmFj {

        -webkit-justify-content: center;

                justify-content: center;
        
    }
}
@media screen and (max-width:750px) {
    .ContactHeader_ContactHeaderHero__1K1Qu {
        height:290px;
        
    }
    .ContactHeader_ContactHeaderHero__1K1Qu .ContactHeader_TextBox__Mtm7D {
        font-size:1.3rem;
        margin-top:30px;
        width:400px;

    }
}
@media screen and (max-width:520px) {
    .ContactHeader_ContactHeaderHero__1K1Qu {
        height:250px;
        
    }
    .ContactHeader_ContactHeaderHero__1K1Qu .ContactHeader_TextBox__Mtm7D {
        font-size:1.1rem;
        width:350px;
        margin-top:25px;
    }
}
@media screen and (max-width:400px) {

    .ContactHeader_ContactHeaderHero__1K1Qu .ContactHeader_TextBox__Mtm7D {
        font-size:1rem;
        width:90%;

    }
}
.ContactAvailable_ContactAvailable__1vte7 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    height:500px;
}
.ContactAvailable_ContactContacts__1C9ht {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around; /* IE fallback */
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;

}
.ContactAvailable_ContactAvailableBackground__2Eq8T {
    width: 100%;
    height: 500px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.678);
    z-index: -1;
}
.ContactAvailable_ContactAvailable__1vte7 h2 {
    margin-top:20px;
    font-size:2rem;
    font-weight: 200;
    text-align: center;
}
.ContactAvailable_ContactPerson__2Yabb {
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    margin: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}
.ContactAvailable_ContactPerson__2Yabb .ContactAvailable_ContactProfile__2_TbN {
    height:200px;
    margin-bottom:10px;
    border-radius: 50%;
   
}
.ContactAvailable_ContactPerson__2Yabb .ContactAvailable_ContactProfileInfo__1k1g4 {
    display:-webkit-flex;
    display:flex;
    width:100%;
    margin:5px 0;
    -webkit-align-items: center;
            align-items: center;

}
.ContactAvailable_ContactPerson__2Yabb .ContactAvailable_ContactProfileInfo__1k1g4 a {
    color:rgb(49, 49, 49);
    text-decoration: none;
    font-size:1rem;
    padding-left: 5px;

}


@media screen and (max-width:740px) {

    .ContactAvailable_ContactPerson__2Yabb .ContactAvailable_ContactProfile__2_TbN {
        height:180px;

    }

}
@media screen and (max-width:550px) {
    .ContactAvailable_ContactPerson__2Yabb {
        margin: 40px 10px;
    }
    .ContactAvailable_ContactPerson__2Yabb .ContactAvailable_ContactProfile__2_TbN {
        height:160px;

    }
    .ContactAvailable_ContactAvailable__1vte7 h2 {
        margin-top:10px;
        font-size:1.8rem;

    }
    .ContactAvailable_ContactPerson__2Yabb .ContactAvailable_ContactProfileInfo__1k1g4 a {
        
        font-size:0.8rem;
    
    }
}
@media screen and (max-width:428px) {
    .ContactAvailable_ContactAvailable__1vte7 {

        height:-webkit-max-content;

        height:-moz-max-content;

        height:max-content;
        margin-bottom:20px;
    }
    .ContactAvailable_ContactContacts__1C9ht {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    .ContactAvailable_ContactPerson__2Yabb {
        margin: 20px 10px;
    }
    .ContactAvailable_ContactPerson__2Yabb .ContactAvailable_ContactProfile__2_TbN {
        height:130px;

    }
    .ContactAvailable_ContactAvailable__1vte7 h2 {
        margin-top:10px;
        font-size:1.5rem;

    }
}
.ContactForm_ContactForm__2yTuZ {
    background-color:#796a7c;
    width:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding: 20px 0 60px 0;
    color:white;
}
.ContactForm_ContactForm__2yTuZ > * {
    margin: 0 5px;
}

.ContactForm_ContactFormInfo__21y-p {
    text-align: center;
    max-width:500px;
    padding: 30px 5px;
    /* border:1px solid white; */

}
.ContactForm_ContactFormInfo__21y-p > p {
    font-size: 1.4rem;
    font-weight: 500;

}

.ContactForm_ContactFormForm__jjG08 {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

}
.ContactForm_ContactForm__2yTuZ .ContactForm_contactFormHeadline__31fuP {
    color:white;
    font-weight: 200;
    margin: 10px 0;
    text-align: center;
}
.ContactForm_ContactForm__2yTuZ .ContactForm_contactFormGreeting__129dm {
    color:white;
    font-weight: 200;
    margin: 10px 0;
    text-align: center;
    position: absolute;
    width:100%;
 
}
.ContactForm_ContactForm__2yTuZ .ContactForm_InputFields__lfBNZ {
    max-width:600px;

    display: -webkit-flex;

    display: flex;
    
    -webkit-flex-wrap: wrap;
    
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    
}
.ContactForm_ContactForm__2yTuZ .ContactForm_InputFields__lfBNZ >:first-child {
    max-width:calc(100% - 6px);

    
}
.ContactForm_ContactForm__2yTuZ .ContactForm_InputFields__lfBNZ > * {
    max-width:calc(50% - 6px);
    min-width:200px;
    margin:3px;
    -webkit-flex-grow:1;
            flex-grow:1;
    
    
}
.ContactForm_ContactForm__2yTuZ .ContactForm_InputFields__lfBNZ > div:last-child {
    justify-self: self-start;
}

.ContactForm_contactSubmitButton__reBxL {
	box-shadow:inset 0px 39px 0px -24px #ffffff;
	background-color:#f9f9f9;
	border-radius:4px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-size:1.2rem;
	padding:6px 15px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;


}

.ContactForm_contactSubmitButton__reBxL:hover {
    background-color:#e9e9e9;
}
.ContactForm_contactSubmitButton__reBxL:active {
	
	top:1px;
}

@media screen and (max-width:800px) {
    .ContactForm_ContactForm__2yTuZ {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .ContactForm_ContactFormInfo__21y-p {
        padding: 20px 5px;
        margin-bottom: 20px;
    }
    .ContactForm_ContactFormInfo__21y-p > p {
        font-size: 1.2rem;

    
    }
}
@media screen and (max-width:428px) {
    .ContactForm_ContactForm__2yTuZ .ContactForm_InputFields__lfBNZ >:first-child {
        max-width:calc(50% - 6px);
        
    }
    .ContactForm_ContactFormInfo__21y-p > p {
        font-size: 1.1rem;
    
    }
}
* {
    box-sizing: border-box;
  }
  
  
  .field {

    width: 100%;
    height: 56px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.3);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  }
  
  .field:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }
  
  .field.active {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  }
  
  .field.active input {
    padding: 24px 16px 8px 16px;
  }
  
  .field.active input + label {
    top: 4px;
    opacity: 1;
    color: #6f6188;
  }
  
  .field.locked {
    pointer-events: none;
  }
  
  .field input {
    width: 100%;
    height: 56px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
      0.1s padding ease-in-out;
    -webkit-appearance: none;
  }
  
  .field input::-webkit-input-placeholder {
    color: rgb(202, 202, 202);
  }
  
  .field input::-ms-input-placeholder {
    color: rgb(202, 202, 202);
  }
  
  .field input::placeholder {
    color: rgb(202, 202, 202);
  }
  
  .field input + label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }
  
  .field input + label.error {
    color: #ec392f;
  }
  
  .field p.predicted {
    position: absolute;
    top: 8px;
    left: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #e0e0e0;
    opacity: 1;
    pointer-events: none;
  }
  
.ContactFind_ContactFind__19kjb {
    background:white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    min-height: 500px;
    margin: 20px 0;
}
.ContactFind_ContactFindDescription__1Yi5i {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin:20px;
  text-align: center;

}
.ContactFind_ContactFindDescription__1Yi5i h2 {
  font-weight: 200;
  font-size: 2rem;
}
.ContactFind_ContactFindDescription__1Yi5i > p:nth-child(2) {
  font-weight: 200;
  font-size: 1.3rem;


}
.ContactFind_ContactFindDescription__1Yi5i p:nth-child(3) {
  padding-top:20px;
  font-weight: 200;
  font-size: 1.2rem;
  line-height: 1.5rem;
  height:100%;

  

}


@media screen and (max-width:1100px) {
  .ContactFind_ContactFind__19kjb {
    min-height: 400px;
  } 
}
@media screen and (max-width:840px) {
  .ContactFind_ContactFind__19kjb {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  } 
}

.ContactMap_ContactMap__6ZFxm {
    width:600px;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.ContactMap_ContactMapContainer__1cyl0 {
    width:500px;
    height:500px;
    position: relative;
}

@media screen and (max-width:1100px) {
    .ContactMap_ContactMapContainer__1cyl0 {
        width:400px;
        height:400px;
    } 
    .ContactMap_ContactMap__6ZFxm {
        width:400px;
        margin:0 20px;
    }
}
@media screen and (max-width:460px) {
    .ContactMap_ContactMapContainer__1cyl0 {
        width:400px;
        height:400px;
    } 
    .ContactMap_ContactMap__6ZFxm {
        width:100%;
        margin:0;
    }
}
@media screen and (max-width:420px) {
    .ContactMap_ContactMapContainer__1cyl0 {
        width:350px;
        height:350px;
    } 

}
@media screen and (max-width:367px) {
    .ContactMap_ContactMapContainer__1cyl0 {
        width:300px;
        height:300px;
    } 

}
