.ContactHeaderHero {

    /* background-color:rgba(92, 115, 120, 0.76); */
    background-color:rgba(154, 147, 158, 0.8);
    
    
    width:100%;
    height:350px;
    overflow: hidden;
    
}

.ContactHeaderHero .ContactHeaderOverlay {
    position: absolute;
    width:100%;
    height:100%;
    background:rgba(32, 22, 41, 0.76);
    z-index: 2;
}

.ContactHeaderHero .TextBox {
    position: absolute;
    font-size:1.6rem;
    font-weight:200;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:50px;
    margin-left:180px; 
    z-index: 3;
    width:600px;
    color:#3a3939;
    /* color:rgb(78, 78, 78); */
    top:70px;
}
.ContactHeaderHero .TextBox h2 {
    align-self: flex-start;
}


.ContactHeaderCover { 
    position: relative;
    width: 100%;
    height: 100%;
  }

.ContactBackgroundIllustration {
    position: absolute;
    width:100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    
}
.ContactBackgroundIllustration img {
    width:50vw;
    margin-right: 10vw;   
}

@media screen and (max-width:1500px) {
    .ContactHeaderHero .TextBox {
        font-size:1.3rem;
        margin-left:70px; 
        width:400px;

    }
}
@media screen and (max-width:963px) {
    .ContactHeaderHero .TextBox {
        font-size:1.5rem;
        margin-left:auto; 
        margin-right:auto;
        left:0;
        right:0;
        width:500px;

    }
    .ContactBackgroundIllustration {

        justify-content: center;
        
    }
}
@media screen and (max-width:750px) {
    .ContactHeaderHero {
        height:290px;
        
    }
    .ContactHeaderHero .TextBox {
        font-size:1.3rem;
        margin-top:30px;
        width:400px;

    }
}
@media screen and (max-width:520px) {
    .ContactHeaderHero {
        height:250px;
        
    }
    .ContactHeaderHero .TextBox {
        font-size:1.1rem;
        width:350px;
        margin-top:25px;
    }
}
@media screen and (max-width:400px) {

    .ContactHeaderHero .TextBox {
        font-size:1rem;
        width:90%;

    }
}