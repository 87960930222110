.IntroServices {
    display:flex;
    justify-content: space-around;
    width:100%;
    margin-top:calc(1.2rem + 450px);
}

.IntroServices .IntroServiceBox {
    min-width: 400px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.2s ease-in-out;
    text-decoration: none;

}
.IntroServices .IntroServiceBox p {
    color:white;
    font-weight: 500;
    /* font-size: 3vmax; inline styles to support IE*/
}

.IntroServices .IntroServiceBox:hover {
    transform: scale(1.1);
    cursor: pointer;
    opacity: 0.7;

}

.IntroServiceAir {
    background:url('../../../../assets/images/backgrounds/air-freight.jpg');
    background-size: cover;
    
}
.IntroServiceRoad {
    background:url('../../../../assets/images/backgrounds/logistics-lorry.jpg');
    background-size: cover;
}
.IntroServiceSea {
    background:url('../../../../assets/images/backgrounds/sea-freight2000.jpg');
    background-size: cover;
}

.IntroServiceExplanation {
    font-weight:400;
    width: 100%;
    text-align: center;
    font-size: 2.2rem;
    margin-top:-80px;
    position: absolute;
}

@media (max-width:1280px) {

    .IntroServices .IntroServiceBox {

        min-width: 300px;
        min-height: 200px;
        margin:20px 0;

    }
    .IntroServiceExplanation {

        font-size: 2rem;
        margin-top:-60px;
    }
}
@media (max-width:980px) {
    .IntroServices {
        display:flex;
        flex-direction: column;
        align-items: center;
        margin-top:calc(1.2rem + 400px);

    }
    .IntroServiceAir {
        background:url('../../../../assets/images/backgrounds/air-freight-horizontal.jpg');
        background-size: cover;
        
    }
    .IntroServiceRoad {
        background:url('../../../../assets/images/backgrounds/logistics-lorry-horizontal.jpg');
        background-size: cover;
    }
    .IntroServiceSea {
        background:url('../../../../assets/images/backgrounds/sea-freight2000-horizontal.jpg');
        background-size: cover;
    }
    .IntroServices .IntroServiceBox {
        min-width: 60%;
        min-height: 100px;
        margin:20px 0;

    }
    .IntroServiceExplanation {
        font-size: 1.5rem;
        margin-top:0px;
        position: static;
    }
}
@media (max-width:600px) {
    .IntroServices {
        margin-top:calc(1.2rem + 450px);
    }
    .IntroServiceExplanation {
        font-size: 1.2rem;
    }
    .IntroServiceExplanation {
        font-weight:400;
        width: 100%;
        text-align: center;
        font-size: 1.2rem;

    }
}