.WhyHeaderHero {
    background-color:#9A909C;
    width:100%;
    height:280px;
    
}

.WhyHeaderHero .WhyHeaderOverlay {
    position: absolute;
    width:100%;
    height:100%;
    background:rgba(53, 53, 53, 0.707);
    z-index: 2;
}

.WhyHeaderHero .WhyHeaderText {
    font-size: 3.5rem;
    font-weight: 400;
    color:white;
    position: absolute;
    margin-top:-170px; 
    right:10vw;
    z-index: 3;

}
.WhyHeaderHero .TextBox {
    position: absolute;
    background-color: #e5e5e5;
    height:100px;
    padding:20px 0;
    color:rgb(29, 28, 28);
    font-size:1.4rem;
    font-weight:200;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:-50px;
    left:17vw;
    z-index: 3;
    width:600px;

}
.WhyHeaderHero .TextBox p {
    width:90%;
}

.WhyHeaderCover { 
    position: relative;
    width: 100%;
    height: 100%;
  }
.WhyHeaderCover img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }

  @media screen and (max-width:1100px) {
    .WhyHeaderHero .TextBox {
        height:100px;
        padding:20px 0;
        width:450px;
        font-size:1.2rem;
        
    }
  }
  @media screen and (max-width:900px) {
    .WhyHeaderHero .TextBox {
        left:0 !important;
        right:0 !important;
        margin-left: auto;
        margin-right: auto;
    }
    .WhyHeaderHero .WhyHeaderText {
        font-size: 3rem;
        left:0 !important;
        right:0 !important;
        text-align: center;
    
    }
  }
  @media screen and (max-width:500px) {
    .WhyHeaderHero .TextBox {
        font-size: 1rem;
        height:80px;
        padding:10px 0;
        width: calc(96vw - 10px);
        margin-top:-6vh; 
    }
    .WhyHeaderHero .WhyHeaderText {
        font-size: 2.5rem;

    
    }
  }