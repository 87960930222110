.AboutProfile {
    display:flex;
    margin:2vw;
    align-items: center;
    justify-content: center;
    margin-top:80px;

}
.AboutProfile .ProfileInfo {
    align-self: flex-start;

}

.AboutProfileMobileName {
    display:none;

}
.AboutProfile h2 {    
    margin-left:20px;
    font-size:1.8rem;
    font-weight: 400;
   

}
.AboutProfile  a {    
    margin-left:20px;
    color:purple;

   

}
.AboutProfile .ProfileInfo p {    
    margin-top:40px;

}

.AboutProfile img {
    width:300px;
    box-shadow: 1px 1px 3px 1px #b6b6b6;
    border:1px solid rgb(83, 82, 82);
}
.AboutProfile .ProfileInfo p {
    max-width:600px;
    padding:0px 20px;
    
}
@media screen and (max-width:1377px) {
    .AboutProfile img {
        width:200px;
    }

}
@media screen and (max-width:1070px) {
    .AboutProfile img {
        width:180px;
    }

}
@media screen and (max-width:890px) {
    .AboutProfile img {
        width:170px;
    }
    .AboutProfile .ProfileInfo p {    
        margin-top:20px;
    
    }

}
@media screen and (max-width:590px) {
    .AboutProfile {
        flex-direction: column;
        margin-top:50px;
    
    }
    .AboutProfile > * {
        margin:10px 0;
    
    }
    .AboutProfile .ProfileInfo {
        display:flex;
        flex-direction: column;
        order:2;
    }
    .AboutProfile .ProfileInfo p {
        text-align: center;
        margin-top:0px;
    }
    .AboutProfile  h2 {   
        text-align: center; 
        margin-left:0px;
        font-size:1.8rem;
        font-weight: 400;
       
    
    }
    .AboutProfile  a {    
        margin-left:0px;
        align-self:center;
    }
    .AboutProfile .ProfileInfo  h2 {   
        display:none;
       
    
    }
    .AboutProfile .ProfileInfo  a {    
        display:none;
    }
    .AboutProfileMobileName {
        display:flex;
        flex-direction: column;
        justify-content: center;
    }

}
