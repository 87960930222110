.HomeHero {
    height:110vh;
    /* width:100vw; */
    overflow: hidden;
}
.HomeHeroOverlay {
    height:110vh;
    width:100%;
    background-color:rgba(22, 22, 22, 0.226);
    z-index: 1;
    position: absolute;
}
.HomeHeroRoad {
    background-image: url('../../../assets/images/backgrounds/logistics-lorry-thin.jpg');
    width:100%;
    height:110vh;
    background-size: cover;
    position: absolute;
    overflow: hidden;
   
    
}
.HomeHeroSea {
    background-image: url('../../../assets/images/backgrounds/sea-freight2000.jpg');
    width:100%;
    height:110vh;
    background-size: cover;
    position: absolute;
    overflow: hidden;
}
.HomeHeroAir {
    background-image: url('../../../assets/images/backgrounds/air-freight.jpg');
    width:100%;
    height:110vh;
    background-size: cover;
    position: absolute;
    overflow: hidden;
}

.HomeHero .LeftTextBox {
    margin-left:5vw;
    animation-duration: 0.5s;
    animation-name: slideleft;
}
.HomeHero .RightTextBox {
    margin-right: 5vw;
    animation-duration: 0.5s;
    animation-name: slideright;
}
.HomeHero .LeftTextBox p {
    transform:translateX(10vw);
}
.HomeHero .RightTextBox h2 {
    transform:translateX(-10vw);

}
.HomeHero .HomeHeroTextBox {
    color: white;
    z-index: 10;
    position: relative;
}
.HomeHero .HomeHeroTextBox h2 {
    font-size: 3rem;
}
.HomeHero .HomeHeroTextBox p {
    font-size: 1rem;
    font-weight: 200;
}
.HomeHero .HomeHeroRoadTextBox {
    margin-top:60vh;
}
.HomeHero .HomeHeroSeaTextBox {
    margin-top:50vh;
    
}
.HomeHero .HomeHeroAirTextBox {
    margin-top:200px;
    text-align: right;
}

@media (min-width: 600px) {
    .HomeHero .HomeHeroTextBox h2 {
        font-size: 4rem;
    }
    .HomeHero .HomeHeroTextBox p {
        font-size: 1.5rem;
        font-weight: 200;
    }
    
}
@media (min-width: 740px) {
    .HomeHeroRoad {
        background-image: url('../../../assets/images/backgrounds/logistics-lorry.jpg');

       
        
    }
    
}
@media (min-width: 1300px) {
    .HomeHero .HomeHeroTextBox h2 {
        font-size: 6rem;
    }
    .HomeHero .HomeHeroTextBox p {
        font-size: 2rem;
        font-weight: 200;
    }
    
}


@keyframes slideleft {
  from {
    transform:translateX(-100vw)
  }

  to {
    transform:translateX(0vw)
   
  }
}
@keyframes slideright {
    from {
        transform:translateX(100vw)
      }
    
      to {
        transform:translateX(0vw)
       
      }
}