.AboutHeader {
  height: 70vh;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 0 5px 1px #909090;
}

.AboutHeader img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1377px) {
  .AboutHeader {
    height: 470px;
    overflow: hidden;
  }
}
@media screen and (max-width: 1070px) {
  .AboutHeader {
    height: 340px;
    overflow: hidden;
  }
}
@media screen and (max-width: 890px) {
  .AboutHeader {
    height: 280px;
  }
}
@media screen and (max-width: 590px) {
  .AboutHeader {
    height: 200px;
  }
}
@media screen and (max-width: 420px) {
  .AboutHeader {
    height: 160px;
  }
}
