.About {
    height:100%;
    display:flex;
    flex-direction: column;
}
.AboutBody {
    height:100%;
    display:flex;
    flex-direction: column;
    background-color: #E2DBE3;
}