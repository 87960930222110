.BurgerMenu {
    display:none;
}


@media (max-width:600px) {
    .BurgerMenu {
        display:block;
    }
    
}