.ContactAvailable {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height:500px;
}
.ContactContacts {
    display: flex;
    justify-content: space-around; /* IE fallback */
    justify-content: space-evenly;

}
.ContactAvailableBackground {
    width: 100%;
    height: 500px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.678);
    z-index: -1;
}
.ContactAvailable h2 {
    margin-top:20px;
    font-size:2rem;
    font-weight: 200;
    text-align: center;
}
.ContactPerson {
    width:fit-content;
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.ContactPerson .ContactProfile {
    height:200px;
    margin-bottom:10px;
    border-radius: 50%;
   
}
.ContactPerson .ContactProfileInfo {
    display:flex;
    width:100%;
    margin:5px 0;
    align-items: center;

}
.ContactPerson .ContactProfileInfo a {
    color:rgb(49, 49, 49);
    text-decoration: none;
    font-size:1rem;
    padding-left: 5px;

}


@media screen and (max-width:740px) {

    .ContactPerson .ContactProfile {
        height:180px;

    }

}
@media screen and (max-width:550px) {
    .ContactPerson {
        margin: 40px 10px;
    }
    .ContactPerson .ContactProfile {
        height:160px;

    }
    .ContactAvailable h2 {
        margin-top:10px;
        font-size:1.8rem;

    }
    .ContactPerson .ContactProfileInfo a {
        
        font-size:0.8rem;
    
    }
}
@media screen and (max-width:428px) {
    .ContactAvailable {

        height:max-content;
        margin-bottom:20px;
    }
    .ContactContacts {
        flex-direction: column;
        align-items: center;
    }
    .ContactPerson {
        margin: 20px 10px;
    }
    .ContactPerson .ContactProfile {
        height:130px;

    }
    .ContactAvailable h2 {
        margin-top:10px;
        font-size:1.5rem;

    }
}