.HomeIntro {
    height:900px;
    width:100%;
    display: flex;
    flex-direction: column;
    color:#333333;
    margin-top:-10vh;
}

.HomeIntro .Title {

    left:20%;
    width: max-content;
    background-color: #453748;
    position: absolute;
    z-index: 2;
    margin-top:10px;
    padding: 10px 20px;
    margin-left:-15px;
}
.HomeIntro .TitleIE {

    width: max-content;
    position: absolute;
    z-index: 2;
    margin-top:10px;
    padding: 10px 20px;
    margin-left:-15px;
    width: 100%;

}
.HomeIntro .TitleIE h2 {

    font-weight:200;
    font-size: calc(1.2rem + 2.5vw);
    text-align: center;
    color:rgb(63, 63, 63);

}

.HomeIntro .Title h2 {
  
    font-weight:200;
    font-size: calc(1.2rem + 2.5vw);
    text-align: center;
    color:white;
}
.ArticleContainer {
    clip-path: polygon(0 7%, 100% 0, 100% 42%, 0% 100%);
    height:100vh;
    width:100%;
    background:#E2DBE3;
    display:flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: absolute;
 
}
.ArticleContainerIE {
    height:100vh;
    max-height:500px;
    width:100%;
    background:#E2DBE3;
    display:flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: absolute;
 
}
.ArticleContainer article, .ArticleContainerIE article {
    margin-top: 110px;
    padding: 20px;
    width:90%;
    font-size:1.2rem;
    text-align: center;
    font-weight:100;

}
.IntroServicesContainer {
   /*  transform:translateY(-20vh); */
    z-index: 1;
    width:100%;
    position: absolute;

}

@media (min-width: 400px) {

    .ArticleContainer article {
 
        margin-top: 170px;

    }
    .HomeIntro .Title {

        margin-left:0px;
    }
    
}
@media (min-width: 600px) {
    .HomeIntro .Title {
        left:10%;
        transform: rotateZ(-4deg);
        margin-top:12px;
    }
    .ArticleContainer article, .ArticleContainerIE article {
        width:90%;
        font-size:1.4rem;
        margin-top: 170px;

    }
    
}
@media (min-width: 1300px) {
    .HomeIntro .Title {
        transform: rotateZ(-2deg)  
    }
    .ArticleContainer article, .ArticleContainerIE article {
        width:70%;
        font-size:1.6rem;

    }
    
}