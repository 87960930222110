.ServiceConsulting {
    display: flex;
    flex-direction: column;
    padding: 80px 0;
    position: relative;
    height:678px
}

.ConsultingContent {
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    
}

.ServiceConsulting h4 {
    font-size: 4rem;
    font-weight:400;
    text-align: left;
    color:#615766;
    width:80%;
    padding-left:10px;
    transform:translateY(-50px)

}
.ConsultingImageContainer {
    position:absolute;
    right:50px;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    padding:20px;
}
.ParagraphContainer {
    padding:20px 50% 20px 40px;
    background:#615766;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


}
.ServiceConsulting p {
    font-size: calc(0.5rem + 1.1vw);
    font-weight: 200;
    text-align: center;
    margin: 10px 0;
    color:white;
    
}
.ServiceConsulting img {
    width: 500px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
}
@media screen and (max-width:1200px) {
    .ServiceConsulting img {
        width: 400px;
    }
    .ConsultingImageContainer {
        right:5px;
    }
}

@media screen and (max-width:880px) {
    .ServiceConsulting h4 {
        font-size: 3rem;

    }
    .ConsultingContent {
        flex-direction:column;
    }
    .ConsultingContent > div{
        order:2;
    }
    .ConsultingContent > p {
        order:1;
    }
    .ConsultingImageContainer {
        right: 0;
        left:50%;
        width:fit-content;
        bottom:90px;
        padding:0;
        transform:translateX(-50%)
    }
    .ServiceConsulting img {
        width: 300px;
    }
    .ParagraphContainer {
        padding:5px 0 50px 0;
    }
    .ServiceConsulting {
        height:600px
    }
}
@media screen and (max-width:617px) {
    .ServiceConsulting {
        padding: 70px 0;
    }
    .ServiceConsulting h4 {
        font-size: 2.7rem;
    }
}
@media screen and (max-width:428px) {
    .ServiceConsulting h4 {
        font-size: 1.6rem;
        transform:translateY(-10px)
    }
    .ServiceConsulting {
        padding: 20px 0;
        height:470px
    }
    .ServiceConsulting img {

        width: 240px;
        transform:translateY(10px)
        
    }
}
@media screen and (max-width:380px) {

    .ServiceConsulting img {

        transform:translateY(30px)
        
    }
}