.ProfileSkills {
    display: flex;
    flex-direction: column;
    order:0;
    width:200px;
    margin-top:-170px;
    
}
.ProfileSkillItem {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.CrossWrapper {
      width:25px;
}
.ProfileSkillCross {
    background: #4b3c4f;
    height: 20px;
    position: relative;
    width: 4px;
  }
.ProfileSkillCross:after {
    background: #4b3c4f;
    content: "";
    height: 4px;
    left: -8px;
    position: absolute;
    top: 8px;
    width: 20px;
  }

  @media screen and (max-width:1377px) {
    .ProfileSkills {

        margin-top:0px;
        
    }

}

  @media screen and (max-width:590px) {
    .ProfileSkills {
        margin-left:25%;
        
    }

}