.ServiceWarehouse {
    display: flex;
    flex-direction: column;
    padding: 50px 0; 
}

.ServiceWarehouse h4 {
    font-size: 2.8rem;
    text-align: center;
    margin: 20px 0 10px 0;
    color:#424141;
    
}
.ServiceWarehouse p {
    font-size: calc(0.5rem + 1.4vw);
    font-weight: 200;
    text-align: center;
    max-width: 80%;
    margin: 10px 0;
    color:rgb(46, 46, 46);
    
}
.ServiceWarehouse img {
    width: 500px;

    margin: 40px 0;
    /* border: 1px solid rgb(99, 99, 99); */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;
    
}

@media screen and (max-width:840px) {
    .ServiceWarehouse h4 {
        font-size: 2.1rem;
    }

    .ServiceWarehouse img {
        /* width:500px; */
        width: 400px;

    }
}
@media screen and (max-width:617px) {
    .ServiceWarehouse h4 {
        font-size: 1.8rem;

        
    }
    .ServiceWarehouse img {
        /* width:500px; */
        width: 350px;

        
    }
}
@media screen and (max-width:428px) {
    .ServiceWarehouse h4 {
        font-size: 1.6rem;

        
    }
    .ServiceWarehouse {

        padding: 20px 0; 
    }
    .ServiceWarehouse img {
        /* width:500px; */
        width: 250px;

        
    }
}