.WhyBenefits {
    display: flex;
    flex-direction: column;
    width:100%;
    border-top:3px solid #383838;
}
.WhyBenefitsHeader h4 {
    font-size: 2.2rem;
    font-weight: 100;
    width:500px;
    text-align: center;
    color:white;
    background-color: #383838;
    margin-bottom: 20px;
    padding:12px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.WhyBenefitsItemWrapper {
    display: flex;
    flex-wrap:wrap;
    justify-content: space-around;
}

@media screen and (max-width:1000px) {
    .WhyBenefitsHeader h4 {
        font-size: 1.9rem;
        font-weight: 100;
        width:400px;
      
    }
}
@media screen and (max-width:500px) {
    .WhyBenefitsHeader h4 {
        font-size: 1.9rem;
        font-weight: 100;
        width:100%;
      
    }
}
