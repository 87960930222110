.Footer {
    margin:0;
    padding:0;
    display: flex;
    flex-direction: column;
    min-height: 500px;
    padding: 20px 20px;
    justify-content: space-between;
    align-items: center;
    color: #8A6F91;
    background-color: #221C24;
}




.Footer address {
    font-style:normal;
    order:1;
    display:flex;
    flex-direction: column;
    font-size:1.5rem;
   

}
.Footer address hr {
    border:none;
    height:1px;
    color: #8A6F91;
    background-color: rgba(138, 111, 145, 0.664);
}
.Footer .HomeAddress {
    padding: 10px 0;
}
.Footer .PagodaPhone {
    text-decoration: none;
    color:#8A6F91;
    margin-left: 5px;
}
.Footer .InfoMail {
    text-decoration: none;
    color:#8A6F91;
}
.Footer .SocialMedia {
    display: flex;
    order:2;
    align-items: flex-start;
}
.Footer .SocialMedia img {
    width:50px;
    margin:0px 10px;
}

.Footer section {
    display: flex;
    width:100%;
    display:flex;
    justify-content: space-between;
}

.Footer .FooterLogotype {
    height:60px;
}

@media screen and (max-width: 600px) {
    .Footer {
        margin:0;
        padding:0;
        display: flex;
        flex-direction: column;
        height: 400px;
        padding: 20px 20px;
        justify-content: space-between;
        align-items: center;
        color: #8A6F91;
        background-color: #221C24;
    }
    
    .Footer section {
        flex-direction: column;
        height:100%;
        align-items: center;
        justify-content: space-around;
    }
    .Footer address {
        order:2;
        text-align: center;
        margin:20px 0;
        font-size:1.2rem;
        
    }
    .Footer .SocialMedia {
        order:1
    }

    
}